/* Make the svg a white circle and give it the default spinning animation */
.bk{
    backdrop-filter: blur(3px) saturate(200%);
    -webkit-backdrop-filter: blur(3px) saturate(200%);
    background-color: rgba(255, 255, 255, 0.26);
    border-radius: 12px;
    border: 1px solid rgba(209, 213, 219, 0.3);
}
.divM{
   
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 100vh;
        
        font-family: "Montserrat", sans-serif;
      
}
.svg {
    background-color: none;
    border-radius: 50%;
    animation: spin 3s ease infinite alternate;
  }
  
  /*Give each dot a radius of 20*/
  .shape {
    r: 20;
  }
  
  /*Give each dot its positioning and set the default animation and color for each */
  .shape:nth-child(1) {
    cy: 50;
    cx: 50;
    fill: #c20f00;
    animation: movein 3s ease infinite alternate;
  }
  .shape:nth-child(2) {
    cy: 50;
    cx: 150;
    fill: #ffdd22;
    animation: movein 3s ease infinite alternate;
  }
  .shape:nth-child(3) {
    cy: 150;
    cx: 50;
    fill: #2374c6;
    animation: movein 3s ease infinite alternate;
  }
  .shape:nth-child(4) {
    cy: 150;
    cx: 150;
    fill: #000000;
    animation: movein 3s ease infinite alternate;
  }
  
  /* Put the two interface options at the bottom of the screen */
  .control-panel {
    position: fixed;
    bottom: 5px;
    display: flex;
    align-items: center;
  }
  
  /* Set color and placement of labels */
  .switch-label {
    display: inline-block;
    color: #000000;
    margin: 5px;
  }
  
  /* Set area of switches */
  .switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 25px;
  }
  
  /* Get rid of checkbox defaults */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* Create the slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ffdd22;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  .slider:before {
    position: absolute;
    content: "";
    height: 19px;
    width: 19px;
    left: 3px;
    bottom: 3px;
    background-color: #000000;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  
 
  
  /* Round the sliders */
  .slider.round {
    border-radius: 34px;
  }
  .slider.round:before {
    border-radius: 50%;
  }
  
  /*Spin the svg so all the dots spin*/
  @keyframes spin {
    to {
      transform: rotate(1turn);
    }
  }
  
  /* Move all the dots toward the center */
  @keyframes movein {
    to {
      cy: 100;
      cx: 100;
    }
  }
  
  /* Set a bouncy ball type movement for the dots */
  @keyframes moveup {
    to {
      cy: 20;
    }
  }
  .bk
    {
        border: none;
        background: none;
    }
  